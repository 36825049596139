.menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 206px;
    background-color: #006CE4;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-right-radius: 4px; /* 16px */
    border-bottom-left-radius: 4px; /* 16px */
    transition: transform 1.5s ease-in-out;
    transform: translateY(-200%);
    z-index: 9999;
}
  
.menu.open {
    transform: translateY(0);
}

.menu {
    transition: background-color 0.3s, box-shadow 0.3s;
}

.menu.scrolled {
    background-color: rgba(0, 108, 228, 0.8); /* Adjust the rgba value for transparency */
}

.login{
    width: 100%;
    height: 100vh;
    z-index: 9999;
}

nav {
    transition: background-color 0.3s, box-shadow 0.3s;
}

nav.scrolled {
    background-color: rgba(0, 108, 228, 0.8); /* Adjust the rgba value for transparency */
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.5); /* Adjust the rgba value for shadow color */
}