input{
    height: 36px;
    padding: 10px;
    font-size: 12px;
    color: black;
    border: none;
    outline: none;
    border-radius: 4px;
}
button{
    height: 36px;
    width: 82px;
    margin-top: 20px;
    font-size: 12px;
    color: white;
    background-color: linear-gradient(90deg, rgba(0,108,228,1) 0%, rgba(7,151,255,1) 100%, rgba(0,212,255,1) 100%);
    border: 1px solid white;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
}
button:hover{
    transition: 0.75s;
    cursor: pointer;
    border: 1px solid #FF385C;
    background-color: #FF385C;
}