.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contacts-fixed {
  position: fixed;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
}

.loader{
  pointer-events: none;
  width: 80px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .loader {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.install-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(174, 174, 174, 0.1);
}

.install-button:hover {
  background-color: #007bff;
  border: none;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 80px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 60px;
    right: 5px;
    font-size: 22px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure the navbar spans the full width and is fixed at the top */
.position-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000; /* Ensure it appears above other content */
  top: 0;
}

/* Add some padding or margin to the main content to avoid overlap */
.main-content {
  padding-top: 60px; /* Adjust this value based on the height of your navbar */
}

@media (min-width: 1200px)  and (max-width: 1919px) {
  .contacts-fixed {
    position: absolute;
    right: 0px;
    top: 230px;
  }
}