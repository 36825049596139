/* src/index.css or src/styles.css */
@font-face {
  font-family: 'Melodrama';
  src: url('../src/assets/fonts/Melodrama-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Sentient-Bold';
  src: url('../src/assets/fonts/Sentient-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Sentient-Regular';
  src: url('../src/assets/fonts/Sentient-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-Medium';
  src: url('../src/assets/fonts/Sentient-Medium.otf') format('opentype');
  font-weight: medium;
  font-style: medium;
}

@font-face {
  font-family: 'Sentient-Light';
  src: url('../src/assets/fonts/Sentient-Light.otf') format('opentype');
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: 'LightBeach';
  src: url('../src/assets/fonts/LightBeach.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 224 71.4% 4.1%;

      --card: 0 0% 100%;
      --card-foreground: 224 71.4% 4.1%;

      --popover: 0 0% 100%;
      --popover-foreground: 224 71.4% 4.1%;

      --primary: 220.9 39.3% 11%;
      --primary-foreground: 210 20% 98%;

      --secondary: 220 14.3% 95.9%;
      --secondary-foreground: 220.9 39.3% 11%;

      --muted: 220 14.3% 95.9%;
      --muted-foreground: 220 8.9% 46.1%;

      --accent: 220 14.3% 95.9%;
      --accent-foreground: 220.9 39.3% 11%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 20% 98%;

      --border: 220 13% 91%;
      --input: 220 13% 91%;
      --ring: 224 71.4% 4.1%;

      --radius: 0.5rem;
    }

    .dark {
      --background: 224 71.4% 4.1%;
      --foreground: 210 20% 98%;

      --card: 224 71.4% 4.1%;
      --card-foreground: 210 20% 98%;

      --popover: 224 71.4% 4.1%;
      --popover-foreground: 210 20% 98%;

      --primary: 210 20% 98%;
      --primary-foreground: 220.9 39.3% 11%;

      --secondary: 215 27.9% 16.9%;
      --secondary-foreground: 210 20% 98%;

      --muted: 215 27.9% 16.9%;
      --muted-foreground: 217.9 10.6% 64.9%;

      --accent: 215 27.9% 16.9%;
      --accent-foreground: 210 20% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 20% 98%;

      --border: 215 27.9% 16.9%;
      --input: 215 27.9% 16.9%;
      --ring: 216 12.2% 83.9%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply  bg-background text-foreground;
    }
  }

/* Add to your main CSS file (e.g., index.css or App.css) */
.skystay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.skystay-loader-img {
  animation: pulse 1.5s ease-in-out infinite; /* Pulsing animation */
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.5;
  }
}

.img-fluid {
  max-width: 192px; /* Control the size of the logo */
  height: auto;
}

