.input{
    width: 140px;
    height: 36px;
    padding: 10px;
    font-size: 12px;
    color: black;
    border: 1px solid;
    outline: none;
    border-radius: 4px;
}
.input2{
    width: 100%;
    height: 36px;
    padding: 10px;
    font-size: 12px;
    color: black;
    border: 1px solid;
    outline: none;
    border-radius: 4px;
}
.input3{
    width: 60%;
    height: 36px;
    padding: 10px;
    font-size: 12px;
    color: black;
    border: 1px solid;
    outline: none;
    border-radius: 4px;
}
.input4{
    width: 35%;
    height: 36px;
    padding: 10px;
    font-size: 12px;
    color: black;
    border: 1px solid;
    outline: none;
    border-radius: 4px;
}
.signup{
    width: 100%;
    height: auto;
    padding: 10px;
    color: white;
    border: 1px solid white;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
}
.google{
    width: 100%;
    height: auto;
    padding: 10px;
    color: black;
    border: 1px solid black;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
}

